import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import { theme } from '../common/theme';
import SiloLink from './SiloLink';
import { useSiloTrail } from './animations';

const HorizontalLine = styled(Box)`
  height: 1px;
  border: 0.5px solid black;
  border-color: ${({ color }) => theme.colors[color]};
  opacity: ${theme.opacity.faint};
`;

const SiloLinkList = ({
  silos,
  setColor,
  colors,
  isMobile,
  isActive,
  entering,
  shouldAnimate,
  setActiveSilo,
}) => {
  const { text, hoverColor } = colors;
  const trail = useSiloTrail(silos.length, isActive && entering);
  const primarySilos = silos.filter(({ type }) => type === 'primary');
  const secondarySilos = silos.filter(({ type }) => type === 'secondary');

  return (
    <>
      <Box onMouseLeave={() => setColor('white')}>
        {primarySilos.map(({ name, color, ...link }, idx) => {
          const { x, ...rest } = trail[idx];
          return name ? (
            <SiloLink
              className={`silo-link-${idx}`}
              key={idx}
              style={
                shouldAnimate
                  ? {
                      ...rest,
                      transform: x.interpolate(x => `translateX(${x}px)`),
                    }
                  : {}
              }
              data-testid="silo-link"
              onMouseOver={() => setColor(color)}
              onFocus={() => setColor(color)}
              name={name}
              color={text}
              size="h2"
              hoverColor={hoverColor}
              mb={[1, 0]}
              isPrimary
              isMobile={isMobile}
              setActiveSilo={setActiveSilo}
              {...link}
            />
          ) : null;
        })}
      </Box>
      <HorizontalLine color={text} my={5} />
      <Flex
        onMouseLeave={() => setColor('white')}
        width="fit-content"
        flexDirection={['column', 'column', 'column', 'row']}
      >
        {secondarySilos.map(({ name, subheading, color, ...link }, idx) => {
          const { x, ...rest } = trail[primarySilos.length + idx];
          return name ? (
            <SiloLink
              key={primarySilos.length + idx}
              style={
                shouldAnimate
                  ? {
                      ...rest,
                      transform: x.interpolate(x => `translateX(${x}px)`),
                    }
                  : {}
              }
              onMouseOver={() => setColor(color)}
              onFocus={() => setColor(color)}
              name={name}
              color={text}
              size="h3"
              hoverColor={hoverColor}
              subheading={subheading}
              mb={[3, 4]}
              mr={[3, 3, 8, 15]}
              isMobile={isMobile}
              {...link}
            />
          ) : null;
        })}
      </Flex>
    </>
  );
};

SiloLinkList.propTypes = {
  /** The silos to render */
  silos: PropTypes.array,
  /** Callback to set the primary color */
  setColor: PropTypes.func,
  /** Colors of different elements */
  colors: PropTypes.object,
  /** Is this the mobile view? */
  isMobile: PropTypes.bool,
  /** Is the parent Silo active? */
  isActive: PropTypes.bool,
  /** Is the page entering, or has entered? */
  entering: PropTypes.bool,
  /** Should the Nav animate? */
  shouldAnimate: PropTypes.bool,
  /** set whether the silo is active or not */
  setActiveSilo: PropTypes.func,
};

SiloLinkList.defaultProps = {
  silos: [],
  setColor: () => {},
  colors: {},
  isMobile: false,
  isActive: true,
  entering: false,
  shouldAnimate: false,
  setActiveSilo: () => {},
};

export default SiloLinkList;

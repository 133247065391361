import { useTrail } from 'react-spring';
import { theme } from '../common/theme';

const config = { mass: 5, tension: 2500, friction: 200 };

export const useSiloTrail = (numSilos, activate) =>
  useTrail(numSilos, {
    config,
    to: {
      opacity: activate ? theme.opacity.full : theme.opacity.none,
      x: activate ? 0 : -100,
    },
    from: {
      opacity: theme.opacity.none,
      x: -100,
    },
  });

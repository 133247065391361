import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text/Text';
import Accordion from '../Accordion';
import { Container } from '../Grid';
import { theme } from '../common/theme';
import AnimatedLink from '../AnimatedLink/AnimatedLink';
import getColors from './colors';

const FooterSectionMobile = ({ sectionHeader, links, textColor }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container mx={[0]}>
      <Accordion
        title={sectionHeader}
        isOpen={isOpen}
        onOpen={setIsOpen}
        borderColor={theme.colors.baseGrayLight}
      >
        {links.map(({ displayName, ...link }) => (
          <Text
            key={`link-${displayName}`}
            color={textColor}
            lineHeight={'1'}
            size="body"
            mb={[2]}
            offset={3}
            forwardedAs={link.as}
            {...link}
            as={AnimatedLink}
          >
            {displayName}
          </Text>
        ))}
      </Accordion>
    </Container>
  );
};

FooterSectionMobile.propTypes = {
  /** Header for the section */
  sectionHeader: PropTypes.string,
  /** The list of links to display in the section */
  links: PropTypes.array,
  /** Text color */
  textColor: PropTypes.oneOf(
    Object.values(getColors).map(({ color }) => color),
  ),
};

FooterSectionMobile.displayName = 'FooterSectionMobile';

FooterSectionMobile.defaultProps = {
  sectionHeader: '',
  links: [],
  textColor: 'baseBlack',
};

export default FooterSectionMobile;

import React from 'react';
import PropTypes from 'prop-types';
import { animateOpacity } from '../common/animations';
import { animated } from 'react-spring';

export const Animate = props => {
  const { inView, children } = props;
  //ToDo: Pass in different animation options.
  const springProps = animateOpacity(inView);

  return <animated.div style={springProps}>{children}</animated.div>;
};

Animate.propTypes = {
  /** Is item to be animated in view? */
  inView: PropTypes.bool.isRequired,
  /** Children to be animated */
  children: PropTypes.node.isRequired,
};

Animate.defaultProps = {
  inView: false,
  children: null,
};

export default Animate;

import { createElement } from 'react';
import PropTypes from 'prop-types';
import { Button as RebassButton } from 'rebass';
import styled from 'styled-components';
import { theme } from '../common/theme';

const baseStyles = {
  cursor: 'pointer',
  opacity: 1,
  alignItems: 'center',
  borderRadius: 0,
  fontSize: '14px',
  fontFamily: theme.fonts.dmSans,
  flex: '0 0 auto',
  lineHeight: '1',
  transition: `all ${theme.speed.default} ease`,
};

const display = {
  size: {
    small: {
      fontSize: '12px',
      py: '10px',
      px: '20px',
    },
  },
};

const Button = styled(RebassButton)(() => ({
  ...baseStyles,
}));

export const getColors = {
  //TODO: rename colors
  white: {
    text: theme.colors.baseBlack,
    bg: theme.colors.baseWhite,
    hover: theme.colors.baseGrayLight,
  },
  black: {
    text: theme.colors.baseWhite,
    bg: theme.colors.baseBlack,
    hover: theme.colors.baseGrayDark,
  },
  green: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandGreenBright,
    hover: theme.colors.brandGreen,
  },
  purple: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandPurpleBright,
    hover: theme.colors.brandPurple,
  },
  violet: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandVioletBright,
    hover: theme.colors.brandViolet,
  },
  red: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandRedBright,
    hover: theme.colors.brandRed,
  },
  yellow: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandYellowBright,
    hover: theme.colors.brandYellow,
  },
  navy: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandNavyBright,
    hover: theme.colors.brandNavy,
  },
  purpleGreen: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandGreenPop,
    hover: theme.colors.brandGreen,
  },
  purpleOrange: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandRedPop,
    hover: theme.colors.brandPurplePale,
  },
  xMas: {
    text: theme.colors.baseWhite,
    bg: theme.colors.redBright,
    hover: theme.colors.brandRed,
  },
  greenYellow: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandYellowBright,
    hover: theme.colors.brandYellow,
  },
  transparent: {
    //Hack Alert! Used for China banner.
    text: theme.colors.baseWhite,
    bg: theme.colors.brandYellowBright,
    hover: theme.colors.brandYellow,
  },
  inherit: {
    //Hack Alert! Used for UK Fund banner.
    text: theme.colors.baseWhite,
    bg: theme.colors.carmineRed,
    hover: theme.colors.frenchBlue,
  },
};

export const inverseColors = {
  green: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandGreenBright,
    hover: theme.colors.brandGreen,
    ButtonPrimaryHover: theme.colors.brandGreenPop,
    ButtonActionText: theme.colors.baseWhite,
    ButtonActionHover: theme.colors.brandGreenPop,
  },
  purple: {
    text: theme.colors.baseWhite,
    bg: theme.colors.brandPurpleBright,
    hover: theme.colors.brandPurpleBright,
    ButtonPrimaryHover: theme.colors.brandPurplePop,
    ButtonActionText: theme.colors.baseWhite,
    ButtonActionHover: theme.colors.brandPurplePop,
  },
  violet: {
    text: theme.colors.brandViolet,
    bg: theme.colors.baseWhite,
    hover: theme.colors.brandVioletBright,
    ButtonPrimaryHover: theme.colors.brandVioletPop,
    ButtonActionText: theme.colors.baseWhite,
    ButtonActionHover: theme.colors.brandVioletPop,
  },
  navy: {
    text: theme.colors.brandNavy,
    bg: theme.colors.brandNavyBright,
    hover: theme.colors.brandNavyPop,
    ButtonPrimaryHover: theme.colors.brandNavyPop,
    ButtonActionText: theme.colors.brandNavy,
    ButtonActionHover: theme.colors.brandNavyPop,
  },
  yellow: {
    text: theme.colors.brandYellowBright,
    bg: theme.colors.baseWhite,
    hover: theme.colors.brandYellowPop,
    ButtonPrimaryHover: theme.colors.brandYellowPop,
    ButtonActionText: theme.colors.baseWhite,
    ButtonActionHover: theme.colors.brandYellowPop,
  },
};

const getButtonColors = (color, isInverse, meta) => {
  if (
    meta &&
    meta.customColors &&
    Object.entries(meta.customColors).length !== 0
  ) {
    return meta.customColors;
  }

  if (isInverse && inverseColors[color]) {
    return inverseColors[color];
  }

  return getColors[color] || getColors['black'];
};

const ButtonPrimary = styled(RebassButton)(props => {
  const colorKey = getButtonColors(props.color, props.isInverse, props.meta);

  return {
    ...baseStyles,
    color: colorKey.buttonText || colorKey.text + '!important',
    background: colorKey.ButtonPrimaryBg || colorKey.bg || null,
    borderColor: colorKey.ButtonPrimaryBg || colorKey.bg || null,
    ':hover': {
      backgroundColor: colorKey.ButtonPrimaryHover || colorKey.hover || null,
      borderColor: colorKey.ButtonPrimaryHover || colorKey.hover || null,
    },
  };
});

const ButtonSecondary = styled(RebassButton)(props => {
  const colorKey = getButtonColors(props.color, props.isInverse, props.meta);
  return {
    ...baseStyles,
    color: colorKey.bg || null,
    borderColor: colorKey.bg || null,
    background: '#ffffff',
    ':hover': {
      color: colorKey.hover || null,
      borderColor: colorKey.hover || null,
    },
  };
});

const ButtonAction = styled(RebassButton)(props => {
  const colorKey = getButtonColors(props.color, props.isInverse, props.meta);
  return {
    ...baseStyles,
    color: colorKey.buttonActionText || colorKey.bg || null,
    background: 'transparent',
    ':hover': {
      color: colorKey.buttonActionHover || colorKey.hover || null,
    },
  };
});

const buttons = {
  rebass: Button,
  primary: ButtonPrimary,
  secondary: ButtonSecondary,
  action: ButtonAction,
};

const getButtonProps = props => {
  const { size = null } = props;
  props.className = 'styledButton';

  if (size && display.size[size]) {
    props = {
      ...props,
      ...display.size[size],
    };
  }

  return props;
};

const Element = ({ variant, children, ...props }) =>
  createElement(buttons[variant], getButtonProps(props), children);

Element.defaultProps = {
  variant: 'primary',
  color: 'black',
  px: '32px',
  py: '14px',
  border: '1px solid',
  fontWeight: 'bold',
  isInverse: false,
};

Element.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'action']),
  color: PropTypes.oneOf(Object.keys(getColors)),
  isInverse: PropTypes.bool,
};

export default Element;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { drawings } from '../common/drawings';
import { theme } from '../common/theme';
import { Box } from 'rebass';

const StyledWrapper = styled(Box)`
  position: relative;
  height: 0;
  width: 100%;
`;

const StyledDrawing = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

const StyledPath = styled.path`
  fill: ${props => theme.colors[props.color]};
  transition: fill ${theme.speed.default} ease;
`;

const Drawing = props => {
  const { name, color } = props;

  const drawing = drawings[name] ? drawings[name] : drawings['oversight'];
  // We need th ratio to set the PaddingBottom of the wrapper dif to scale the svg proportionally
  let ratio = (drawing.h / drawing.w) * 100;

  return (
    <StyledWrapper name={name} pb={`${ratio}%`}>
      <StyledDrawing
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${drawing.w} ${drawing.h}`}
        color={color}
      >
        <StyledPath d={drawing.p} color={color} />
      </StyledDrawing>
    </StyledWrapper>
  );
};

Drawing.defaultProps = {
  name: 'logo',
  color: 'baseBlack',
};

Drawing.propTypes = {
  color: PropTypes.oneOf(Object.keys(theme.colors)).isRequired,
  name: PropTypes.oneOf(Object.keys(drawings)).isRequired,
};

export default Drawing;

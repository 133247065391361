import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

import { Container, Row } from '../Grid';
import Text from '../Text/Text';
import Drawing from '../Drawing/Drawing';
import { theme } from '../common/theme';
import ButtonAction from '../ButtonAction/ButtonAction';
import { border } from 'styled-system';
import MenuSection from './../Menu/MenuSection';
import getColors from './colors';
import FooterSectionMobile from './FooterSectionMobile';

const SocialIcon = styled.i`
  font-size: ${theme.fontSizes.intro};
  margin: 0 ${theme.space[1]}px;
  color: ${({ color }) => theme.colors[color]};
  cursor: pointer;
`;

const StyledSocialLinks = styled(Flex)`
  flex-basis: 10%;
`;

const StyledFooterWrapper = styled(Box)`
  ${border};
`;
const Footer = ({ footerSections, color, isMobile }) => {
  const { color: textColor, bg } = getColors[color];

  const socialItems = footerSections.filter(function(footerSection) {
    return footerSection.sectionHeader === 'Social';
  });
  const socialLinks = socialItems[0].links;

  return (
    <StyledFooterWrapper
      bg={bg}
      borderTop="1px solid"
      borderColor={[theme.colors.baseGrayLight, 'transparent']}
      pt={[6, 9]}
    >
      <Container>
        {isMobile && (
          <Box mb={[2, 4]} width={['40px', '55px']}>
            <Drawing color={textColor} name="logo" />
          </Box>
        )}
        <Row
          mb={[0, 10]}
          px={[0]}
          flexDirection={isMobile ? ['column'] : ['row']}
        >
          {footerSections.map(
            ({ sectionHeader, links, displayOnMobile }, sectionIdx) => {
              if (isMobile) {
                if (displayOnMobile === false) return null;
                return (
                  <FooterSectionMobile
                    key={`footer-section-${sectionIdx}`}
                    {...{ sectionHeader, links, sectionIdx, textColor }}
                  />
                );
              } else {
                return (
                  <MenuSection
                    key={`footer-section-${sectionIdx}`}
                    {...{ sectionHeader, links, sectionIdx, textColor }}
                  />
                );
              }
            },
          )}
        </Row>
        {isMobile && (
          <Flex
            mt={[2]}
            mb={[2]}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              {socialLinks &&
                socialLinks.map(({ displayName, ...link }) => (
                  <SocialIcon
                    color={textColor}
                    className={`icon icon-${displayName.toLowerCase()}`}
                    key={`link-${displayName}`}
                    forwardedAs={link.as}
                    {...link}
                  />
                ))}
            </Box>
            <ButtonAction
              isIconAfter={true}
              icon="arrow-up"
              color={color}
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }
            >
              Back to top
            </ButtonAction>
          </Flex>
        )}
        <Flex alignItems="center" pb={[4, 9]}>
          {!isMobile && (
            <Box mr={[5]} width={['40px', '55px']}>
              <Drawing color={textColor} name="logo" />
            </Box>
          )}
          <Box style={{ flexBasis: '100%' }}>
            <Text size="small" color={textColor}>
              © {new Date().getFullYear()} Sygnia | Sygnia Asset Management,
              Sygnia Financial Services, Sygnia Life, Sygnia Consultants, Sygnia
              Benefit Administrators, Sygnia CIS, Sygnia Itrix and Sygnia
              Securities are licensed financial services providers.
            </Text>
          </Box>
          {!isMobile && (
            <StyledSocialLinks>
              {socialLinks &&
                socialLinks.map(({ displayName, ...link }) => (
                  <SocialIcon
                    color={textColor}
                    className={`icon icon-${displayName.toLowerCase()}`}
                    key={`link-${displayName}`}
                    forwardedAs={link.as}
                    {...link}
                  />
                ))}
            </StyledSocialLinks>
          )}
        </Flex>
      </Container>
    </StyledFooterWrapper>
  );
};

Footer.propTypes = {
  /** List of footer sections */
  footerSections: PropTypes.array,
  /** Color combination */
  color: PropTypes.oneOf(Object.keys(getColors)),
  /** Is this the mobile view? */
  isMobile: PropTypes.bool,
};

Footer.defaultProps = {
  footerSections: [],
  color: 'black',
  isMobile: false,
};

export default Footer;

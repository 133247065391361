import styled from 'styled-components';
import { Box } from 'rebass';

export const Container = styled(Box)`
  max-width: 1220px;
`;

Container.defaultProps = {
  mx: 'auto',
  px: '16px',
};

import { createElement } from 'react';
import PropTypes from 'prop-types';
import { Heading as RebassHeading } from 'rebass';
import styled, { css } from 'styled-components';
import { theme } from '../common/theme';
import { getTextProps } from '../common/utils';

const baseStyles = {
  display: 'inherit',
};

const display = {
  size: {
    hero: {
      fontSize: ['h1', 'hero'],
      lineHeight: '1.065',
    },
    h1: {
      fontSize: ['h3', 'h1'],
    },
    h2: {
      fontSize: ['h3', 'h2'],
    },
    h3: {
      fontSize: ['h3'],
    },
    h4: {
      fontSize: 'h4',
    },
    h5: {
      fontSize: 'h5',
      letterSpacing: theme.letterSpacing.small,
    },
    h6: {
      fontSize: 'h6',
      letterSpacing: theme.letterSpacing.small,
    },
  },
};

const Heading = styled(RebassHeading)`
  ${props => ({ ...baseStyles, textTransform: props.textTransform })}
  ${props => props.css && css(...props.css)};
  em {
    color: ${props =>
      props.theme.colors[props.emphasis] || props.emphasis || 'inherit'};
    font-style: normal;
  }
`;

const headings = {
  rebass: Heading,
};

const Element = ({ variant, children, ...props }) =>
  createElement(headings[variant], getTextProps(props, display), children);

Element.defaultProps = {
  variant: 'rebass',
  size: 'hero',
  color: 'baseBlack',
  fontWeight: 'bold',
  fontFamily: 'sans',
  textTransform: 'none',
  lineHeight: '1',
  children: null,
};

Element.propTypes = {
  variant: PropTypes.oneOf(['rebass']),
  size: PropTypes.oneOf(['hero', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  // TODO: Add all theme colors relevant to heading
  color: PropTypes.string,
  fontWeight: PropTypes.oneOf(['normal', 'bold', 'light', '100', '300', '600']),
  fontFamily: PropTypes.oneOf(['sans']),
  textTransform: PropTypes.oneOf([
    'none',
    'uppercase',
    'lowercase',
    'capitalize',
  ]),
  lineHeight: PropTypes.string,
  children: PropTypes.node,
};

export default Element;

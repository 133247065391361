import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Heading from '../Heading/Heading';
import { theme } from '../common/theme';
import { Animate } from '../Animate/Animate';

const StyledCollapse = styled(Flex)`
  ${({ borderColor, isOpen }) =>
    !isOpen ? `border-bottom: 1px solid ${borderColor}` : ''};

  align-items: center;
  justify-content: flex-start;
  outline: none;
`;

const StyledHeading = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  :hover {
    i {
      opacity: ${theme.opacity.full};
    }
  }
`;

const StyledTitle = styled(Heading)`
  line-height: 25px;
  font-size: ${theme.fontSizes.body};
`;

const StyledIcon = styled.i`
  font-size: ${theme.fontSizes.small};
  color: ${theme.colors.baseBlack};
  transform: ${props => (props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: all ${theme.speed.default} ease;
`;

const StyledContent = styled(Box)`
  ${({ isOpen, borderColor }) =>
    isOpen ? `border-bottom: 1px solid ${borderColor}` : ''};
`;

const Accordion = ({
  title,
  children,
  onOpen,
  isOpen,
  isIconAfter,
  icon,
  borderColor,
}) => {
  let [visibility, setVisibility] = useState(false);

  const sectionId = `section-${title}`;
  const labelId = `label-${title}`;

  if (onOpen) {
    visibility = isOpen;
    setVisibility = onOpen;
  }

  return (
    <>
      <StyledCollapse
        onClick={() => setVisibility(!visibility)}
        onKeyDown={e => {
          switch (e.key) {
            case ' ':
            case 'Enter':
              setVisibility(!visibility);
              break;
            default:
          }
        }}
        role="button"
        aria-expanded={visibility}
        aria-controls={sectionId}
        id={labelId}
        tabIndex={0}
        borderColor={borderColor}
        isOpen={isOpen}
      >
        <StyledHeading py={[2]}>
          {!isIconAfter && (
            <StyledIcon
              isOpen={isOpen}
              isIconAfter={isIconAfter}
              className={`icon icon-${theme.icons[icon]}`}
            />
          )}
          <StyledTitle
            mx={[2]}
            size="h4"
            fontWeight={
              visibility ? theme.fontWeight.bold : theme.fontWeight.normal
            }
            fontFamily={theme.fonts.dmSans}
          >
            {title}
          </StyledTitle>
          {isIconAfter && (
            <StyledIcon
              isOpen={isOpen}
              isIconAfter={isIconAfter}
              className={`icon icon-${theme.icons[icon]}`}
            />
          )}
        </StyledHeading>
      </StyledCollapse>

      {visibility ? (
        <Animate inView={visibility}>
          <StyledContent
            role="region"
            aria-labelledby={labelId}
            id={sectionId}
            px={[5]}
            py={[1]}
            isOpen={isOpen}
            borderColor={borderColor}
          >
            {children}
          </StyledContent>
        </Animate>
      ) : null}
    </>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  onToggle: PropTypes.func,
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(Object.keys(theme.icons)),
  isIconAfter: PropTypes.bool,
  onOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  borderColor: PropTypes.oneOf(Object.values(theme.colors)),
};

Accordion.defaultProps = {
  children: null,
  onToggle: () => {},
  title: null,
  icon: 'angleRight',
  isIconAfter: false,
  opOpen: () => {},
  isOpen: false,
  borderColor: theme.colors.baseWhite,
};

export default Accordion;

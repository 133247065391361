import color from 'color';

const size = { xs: 390, small: 576, medium: 768, large: 992, xlarge: 1200 };

export const sizes = {
  giant: size.xlarge,
  desktop: size.large,
  tablet: size.medium,
  phone: size.small,
};

export const mediaQuery = {
  xs: `@media (min-width: ${size.xs}px)`,
  small: `@media (min-width: ${size.small}px)`,
  medium: `@media (min-width: ${size.medium}px)`,
  large: `@media (min-width: ${size.large}px)`,
  xlarge: `@media (min-width: ${size.xlarge}px)`,
};

/** Convert Hex to RGBA */
export const rgba = (hex, opacity) => {
  return color(hex)
    .alpha(opacity)
    .string();
};

export const getTextProps = (props, display) => {
  const { size = null } = props;

  if (size && display.size[size]) {
    props = {
      ...props,
      ...display.size[size],
    };
  }

  if (props.html) {
    props = {
      ...props,
      dangerouslySetInnerHTML: { __html: props.html },
    };

    props.children = null;
  }

  return props;
};

export const convertStringToArray = str => {
  var arr = str
    .slice(1, -1)
    .split(',')
    .map(function(element) {
      if (element.includes('/')) {
        var fractionParts = element.split('/');
        var numerator = parseFloat(fractionParts[0]);
        var denominator = parseFloat(fractionParts[1]);
        return numerator / denominator;
      } else {
        return parseFloat(element);
      }
    });
  return arr;
};

import React from 'react';
import PropTypes from 'prop-types';
import { Column } from '../Grid';
import Text from '../Text/Text';
import { theme } from '../common/theme';
import AnimatedLink from '../AnimatedLink/AnimatedLink';

const MenuSection = ({
  sectionHeader,
  links,
  textColor,
  isFeatured,
  ...props
}) => (
  <Column mb={[2, 0]} {...props}>
    <Text
      color={textColor}
      lineHeight={'1'}
      size="body"
      fontWeight="bold"
      mb={[2]}
    >
      {sectionHeader}
    </Text>
    {links.map(({ displayName, ...link }) => (
      <Text
        key={`link-${displayName}`}
        color={textColor}
        lineHeight={'1'}
        size={isFeatured ? 'intro' : 'body'}
        mb={[2]}
        offset={3}
        forwardedAs={link.as}
        {...link}
        as={AnimatedLink}
      >
        {displayName}
      </Text>
    ))}
  </Column>
);

MenuSection.propTypes = {
  /** Header for the section */
  sectionHeader: PropTypes.string,
  /** The list of links to display in the section */
  links: PropTypes.array,
  /** Text color */
  textColor: PropTypes.oneOf(Object.keys(theme.colors)),
  /** Is this Menu section Featured? */
  isFeatured: PropTypes.bool,
};

MenuSection.defaultProps = {
  sectionHeader: '',
  links: [],
  textColor: 'baseBlack',
  isFeatured: false,
};

export default MenuSection;

import { createElement } from 'react';
import { Text as RebassText } from 'rebass';
import styled, { css } from 'styled-components';
import { theme } from '../common/theme';
import { getTextProps } from '../common/utils';
import { typography } from 'styled-system';

const display = {
  size: {
    tiny: {
      fontSize: 'tiny',
    },
    small: {
      fontSize: 'small',
    },
    body: {
      fontSize: ['small', 'body'],
    },
    intro: {
      fontSize: ['body', 'intro'],
    },
  },
};

const linkStyles = () => {
  return css`
    a {
      text-decoration: underline !important;
      &:hover {
        text-decoration: none !important;
      }
    }
    .styledButton {
      text-decoration: none !important;
    }
  `;
};

const Text = styled(RebassText)`
  ${typography};
  display: inherit;
  ${({ setLinkStyles }) => setLinkStyles && linkStyles()};
`;

const texts = {
  rebass: Text,
};

const Element = ({ variant, children, ...props }) =>
  createElement(texts[variant], getTextProps(props, display), children);

Element.defaultProps = {
  variant: 'rebass',
  size: 'body',
  color: 'baseBlack',
  fontSize: 'body',
  fontFamily: theme.fonts.dmSans,
  fontWeight: theme.fontWeight.light,
  lineHeight: 1.45,
  as: 'span',
  setLinkStyles: true,
};

export default Element;

import React from 'react';
import { Link } from 'gatsby';
import { css } from 'styled-components';
import { CONTENT_TYPES } from './constants';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const mapperWithFunction = (data, map) => {
  const newData = {};

  Object.keys(map).forEach((key, index) => {
    const property = map[key];

    if (typeof property === 'string') {
      newData[key] = data[property];
    } else if (typeof property === 'function') {
      newData[key] = property(data);
    } else {
      let tmp = data;
      property.forEach(p => {
        if (tmp[p]) {
          tmp = tmp[p];
        }
      });
      newData[key] = tmp;
    }
  });

  return newData;
};

export const mapper = (data, map) => {
  const newData = {};
  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr && pathArr?.reduce
      ? pathArr.reduce(
          (obj, key) =>
            obj && obj[key] !== 'undefined' ? obj[key] : undefined,
          nestedObj,
        )
      : null;
  };

  Object.keys(map).forEach(key => {
    const property = map[key];
    const obj = getNestedObject(data, property);
    if (obj) {
      newData[key] = obj;
    }
  });

  return newData;
};

export const setupLink = (link, options) => {
  if (link.file) {
    return {
      as: 'a',
      target: '_blank',
      href: link.file.file?.url || link.file.url || null,
      to: link.file.file?.url || link.file.url || null,
      displayName: link.label || link.name,
      external: true,
      ...options,
    };
  }

  if (link.to && link.to.includes('#') && link.to.includes('/')) {
    return {
      as: 'a',
      href: link.to,
      displayName: link.label || link.name,
      ...options,
    };
  }

  if (link.to && link.to.includes('#')) {
    return {
      as: AnchorLink,
      href: link.to,
      offset: '350',
      displayName: link.label || link.name,
      ...options,
    };
  }

  if (link.to && link.to.includes('mailto:')) {
    return {
      as: 'a',
      href: link.to,
      displayName: link.label || link.name,
      ...options,
    };
  }

  if (link.external) {
    return {
      displayName: link.label || link.name,
      title: link.label || link.name,
      href: link.to,
      target: '_blank',
      as: 'a',
      ...options,
    };
  }

  return {
    displayName: link.label || link.name,
    link: link.to,
    to: link.to,
    as: link.to ? Link : 'span',
    ...options,
  };
};

export const setupContent = (label, slug, options, base) => {
  const parts = [base, slug];

  return {
    displayName: label,
    label: label,
    as: Link,
    to: `/${parts.join('/')}`,
    link: `/${parts.join('/')}`,
    ...options,
  };
};

export const setupPage = (link, options) => {
  const parts = [];

  if (link.contentfulparent) {
    parts.push(link.contentfulparent);
  }

  if (link.slug) {
    parts.push(link.slug);
  }

  let label = link.label;

  if (link.pageSummary) {
    const { title, meta } = link.pageSummary;

    if (meta && meta.label) {
      label = meta.label;
    } else {
      label = title;
    }
  }

  return {
    displayName: label,
    label: label,
    as: Link,
    to: `/${parts.join('/')}`,
    link: `/${parts.join('/')}`,
    ...options,
  };
};

const size = { small: 576, medium: 768, large: 992, xlarge: 1200 };

export const sizes = {
  giant: size.xlarge,
  desktop: size.large,
  tablet: size.medium,
  phone: size.small,
};

export const mediaQuery = {
  small: `@media (min-width: ${size.small}px)`,
  medium: `@media (min-width: ${size.medium}px)`,
  large: `@media (min-width: ${size.large}px)`,
  xlarge: `@media (min-width: ${size.xlarge}px)`,
};

export const screenSmallerThan = Object.keys(sizes).reduce(
  (accumulator, label) => {
    const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {},
);

export const getMenuSilos = pages => {
  const MENU_MAPPING = {
    [CONTENT_TYPES.CONTENT_PAGE]: {
      props: ({ pageSummary, colorScheme, ...rest }) => {
        return (
          pageSummary && {
            name: pageSummary.title.toLowerCase(),
            type: 'primary',
            as: Link,
            to: setupPage(rest).link, //Refactor to use setup only
            color: colorScheme,
          }
        );
      },
    },
    [CONTENT_TYPES.COMPONENT]: {
      props: ({ component: { heading, ...rest } }) => {
        return {
          name: heading,
          ...setupLink(rest),
          ...rest,
        };
      },
    },
  };
  return pages.map(p => {
    const item = MENU_MAPPING[p.__typename];

    if (!item) return null;

    return item.props(p);
  });
};

export const screenLargerThan = Object.keys(sizes).reduce(
  (accumulator, label) => {
    const emSize = sizes[label] / 16;
    accumulator[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {},
);

export const strip_html_tags = str => {
  if (str === null || str === '') {
    return false;
  } else {
    str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }
};

export const postForm = async (values, name) => {
  const response = await fetch(`${process.env.GATSBY_API_URL}/form`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ input: values, form: name }),
  });

  const responseJson = await response.json();

  return responseJson;
};

export const sentenceCase = string => {
  return string
    .toLowerCase()
    .replace(/(^\s*\w|[\.\!\?\-]\s*\w)/g, function(letter) {
      return letter.toUpperCase();
    });
};

export const getEmText = (text, emphasis, type = 'em') => {
  if (!text) return null;
  let emText = <span>{text}</span>;
  if (!emphasis) return emText;

  var n = text.indexOf(emphasis);
  if (n > -1) {
    const preString = text.substring(0, n);
    const emString = text.substring(n, n + emphasis.length);
    const postString = text.substring(n + emphasis.length, text.length);

    emText = (
      <span>
        {preString}
        {type === 'mark' ? <mark>{emString}</mark> : <em>{emString}</em>}
        {postString}
      </span>
    );
  }

  return emText;
};

export const truncateStr = (str, charCount = 20) => {
  if (!str) return null;
  return `${str.substring(0, charCount)} ${
    str.length >= charCount ? '...' : ''
  } `;
};

import styled from 'styled-components';
import { theme } from '../common/theme';
import Text from '../Text/Text';

/** TODO: make offset more user friendly - currently only used in Footer
 *        so will see if more options are necessary. It is only used where
 *        the line height is changed on the Text component, to prevent the
 *        underlining from being too close to the text.
 */
const AnimatedLink = styled(Text)`
  position: relative;
  cursor: pointer;
  width: fit-content;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    bottom: ${({ offset }) => (offset ? `-${offset}px` : 0)};
    height: 1px;
    display: block;
    left: 0;
    background: ${({ color }) => theme.colors[color]};
    transition: width ${theme.speed.default} ease;
  }

  &:hover::after {
    width: 100%;
  }
`;

export default AnimatedLink;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import { animated } from 'react-spring';

import Text from '../Text/Text';
import Heading from '../Heading/Heading';
import { theme } from '../common/theme';
import { silos } from './common';

const SiloLinkWrapper = styled(Box)`
  cursor: pointer;
  text-decoration: none;
  i {
    color: ${({ color }) => theme.colors[color]};
    cursor: pointer;
    transition: all ${theme.speed.default} ease;
    opacity: 0;
  }

  &:hover i {
    color: ${({ hoverColor }) => theme.colors[hoverColor]};
    opacity: 1;
    transform: translateX(${theme.space[1]}px);
  }
`;

const LinkStyle = `
  transition: color ${theme.speed.default} ease;
`;

const StyledLinkPrimary = styled(Heading)`
  ${LinkStyle}

  ${SiloLinkWrapper}:hover & {
    color: ${({ hoverColor }) => theme.colors[hoverColor]};
  }
`;

const StyledLinkSecondary = styled(Heading)`
  ${LinkStyle}

  ${SiloLinkWrapper}:hover & {
    color: ${({ hoverColor }) => theme.colors[hoverColor]};
  }
`;

const StyledLinkSubheading = styled(animated(Text))`
  ${LinkStyle}

  ${SiloLinkWrapper}:hover & {
    color: ${({ hoverColor }) => theme.colors[hoverColor]};
  }
`;

const Icon = () => (
  <Text size="intro">
    <i className={`icon icon-arrow-right`} />
  </Text>
);

const AnimatedFlex = animated(Flex);

const SiloLink = ({
  name,
  color,
  hoverColor,
  subheading,
  isPrimary,
  isMobile,
  style,
  setActiveSilo,
  ...rest
}) => (
  <SiloLinkWrapper
    data-testid="silo-link-wrapper"
    color={color}
    hoverColor={hoverColor}
    onClick={() => setActiveSilo(false)}
    {...rest}
  >
    {subheading ? (
      <StyledLinkSubheading
        size="body"
        color={color}
        hoverColor={hoverColor}
        fontWeight={isMobile ? theme.fontWeight.normal : theme.fontWeight.bold}
        style={style}
      >
        {subheading}
      </StyledLinkSubheading>
    ) : null}
    <AnimatedFlex style={style} alignItems="center">
      {isPrimary ? (
        <StyledLinkPrimary
          data-testid="silo-link-primary"
          size="hero"
          color={color}
          hoverColor={hoverColor}
        >
          {name}
        </StyledLinkPrimary>
      ) : (
        <StyledLinkSecondary
          size="h2"
          color={color}
          hoverColor={hoverColor}
          fontWeight={theme.fontWeight.bold}
        >
          {name}
        </StyledLinkSecondary>
      )}
      <Icon />
    </AnimatedFlex>
  </SiloLinkWrapper>
);

SiloLink.propTypes = {
  name: PropTypes.oneOf(Object.keys(silos).map(key => silos[key].name)),
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  hoverColor: PropTypes.oneOf(['baseBlack', 'baseWhite']),
  subheading: PropTypes.string,
  isPrimary: PropTypes.bool,
  isMobile: PropTypes.bool,
  rest: PropTypes.array,
  style: PropTypes.object,
  setActiveSilo: PropTypes.func,
};

SiloLink.defaultProps = {
  name: null,
  color: 'baseBlack',
  hoverColor: 'baseBlack',
  subheading: null,
  isPrimary: false,
  isMobile: false,
  rest: [],
  style: {},
  setActiveSilo: () => {},
};

export default SiloLink;

export const silos = [
  {
    name: 'for me',
    type: 'primary',
    color: 'violet',
  },
  {
    name: 'for my business',
    type: 'primary',
    color: 'navy',
  },
  {
    name: 'for institutions',
    type: 'primary',
    color: 'purple',
  },
  {
    name: 'for financial advisors',
    type: 'primary',
    color: 'green',
  },
  {
    name: 'for shareholders',
    type: 'primary',
    color: 'yellow',
  },
  {
    name: 'Alchemy',
    type: 'secondary',
    subheading: 'Your investment portal',
    color: 'black',
  },
  {
    name: 'Stargazer',
    type: 'secondary',
    subheading: 'Give the gift of smart investing',
    color: 'red',
  },
];

export const siloColorScheme = {
  white: {
    bg: 'baseWhite',
    text: 'baseBlack',
    hoverColor: 'baseBlack',
    logoColor: 'baseBlack',
  },
  violet: {
    bg: 'brandViolet',
    text: 'brandVioletPop',
    hoverColor: 'baseWhite',
    logoColor: 'baseWhite',
  },
  navy: {
    bg: 'brandNavy',
    text: 'brandNavyPop',
    hoverColor: 'baseWhite',
    logoColor: 'baseWhite',
  },
  purple: {
    bg: 'brandPurple',
    text: 'brandPurplePop',
    hoverColor: 'baseWhite',
    logoColor: 'baseWhite',
  },
  green: {
    bg: 'brandGreen',
    text: 'brandGreenPop',
    hoverColor: 'baseWhite',
    logoColor: 'baseWhite',
  },
  yellow: {
    bg: 'brandYellow',
    text: 'brandYellowPop',
    hoverColor: 'baseWhite',
    logoColor: 'baseWhite',
  },
  red: {
    bg: 'brandRed',
    text: 'brandRedPop',
    hoverColor: 'baseWhite',
    logoColor: 'baseWhite',
  },
  black: {
    bg: 'baseBlack',
    text: 'baseWhite',
    hoverColor: 'baseWhite',
    logoColor: 'baseWhite',
  },
};

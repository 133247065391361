export const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px'],

  fonts: {
    sans: 'SygniaMessinaSans, system-ui, sans-serif',
    mono: 'Menlo, monospace',
    serif: 'Messina Serif Regular, ui-sans-serif, serif',
    dmSans: 'DM Sans, sans-serif',
  },

  fontWeight: {
    bold: '700',
    lightBold: '400',
    normal: '300',
    light: '100',
  },

  fontSizes: {
    // Text sizes
    tiny: '12px',
    small: '14px',
    body: '17px',
    intro: '22px',
    // Heading sizes
    h6: '14px',
    h5: '15px',
    h4: '17px',
    h3: '30px',
    h2: '40px',
    h1: '30px',
    hero: '62px',
  },

  colors: {
    // Base
    white: '#FFFFFF',
    baseWhite: '#FFFFFF',
    baseGrayVeryLight: '#F6F6F6',
    baseGrayLight: '#EAEAEA',
    baseGray: '#969696',
    baseGrayDark: '#747371',
    baseBlack: '#000000',
    transparent: 'transparent',

    // Brand
    yellow: '#E8931B',
    yellowBright: '#FFBA00',
    brandYellow: '#E8931B',
    brandYellowBright: '#FFBA00',
    brandYellowPop: '#FFD166',
    brandYellowPale: '#F6EEE2',

    red: '#AE162A',
    redBright: '#F24342',
    brandRed: '#AE162A',
    brandRedBright: '#F24342',
    brandRedPop: '#FF694B',
    brandRedPale: '#F8EEEF',
    redBerry: '#8d1304', //https://colors.artyclick.com/color-name-finder/
    carmineRed: '#F24342',

    purple: '#653465',
    purpleBright: '#B22AC8',
    brandPurple: '#653465',
    brandPurpleBright: '#B22AC8',
    brandPurplePop: '#D289DE',
    brandPurplePale: '#F7EEF7',

    violet: '#372D6A',
    violetBright: '#5548CF',
    brandViolet: '#372D6A',
    brandVioletBright: '#5548CF',
    brandVioletPop: '#8890FF',
    brandVioletPale: '#EAEEF5',

    blue: '#114569',
    lightBlue: '#57A3FF',
    navy: '#114569',
    navyBright: '#1078BA',
    brandNavy: '#114569',
    brandNavyBright: '#1078BA',
    brandNavyPop: '#70C8FF',
    brandNavyPale: '#E6F0F6',
    astronautBlue: '#043A61',
    frenchBlue: '#1078BA',
    jaggedIce: '#C6E9FF',

    green: '#006950',
    greenBright: '#3CAD6D',
    brandGreen: '#006950',
    brandGreenBright: '#3CAD6D',
    brandGreenPop: '#7AD790',
    brandGreenPale: '#E6F2E7',
    brandGreenTropicalForest: '#01694F',

    // Status
    statusSuccess: '#006950', //brandGreen
    statusError: '#AE162A', //brandRed
    statusWarning: '#E8931B', //brandYellow
    statusInfo: '#1078BA', //brandNavyBright
  },

  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },

  speed: {
    default: '0.3s',
    slow: '0.5s',
    fast: '0.1s',
  },

  letterSpacing: {
    small: '.0335em',
  },

  space: [
    0,
    8,
    16,
    24,
    32,
    40,
    48,
    56,
    64,
    72,
    80,
    88,
    96,
    104,
    112,
    120,
    128,
    136,
    144,
    152,
    160,
    168,
    176,
    184,
    192,
    200,
    208,
    216,
    224,
    232,
    240,
    248,
    256,
  ],

  opacity: {
    none: 0,
    faint: 0.3,
    medium: 0.6,
    full: 1,
  },

  icons: {
    // Icons from FortAwesome. TODO: embed offline
    // https://fortawesome.com/kits/551664b3
    close: 'close',
    menu: 'menu',
    search: 'search',
    play: 'play',
    download: 'download',
    arrowUp: 'arrow-up',
    arrowRight: 'arrow-right',
    arrowDown: 'arrow-down',
    arrowLeft: 'arrow-left',
    angleUp: 'angle-up',
    angleRight: 'angle-right',
    angleDown: 'angle-down',
    angleLeft: 'angle-left',
    caretUp: 'caret-up',
    caretRight: 'caret-right',
    caretDown: 'caret-down',
    caretLeft: 'caret-left',
    email: 'email',
    linkedin: 'linkedin',
    twitter: 'twitter',
    facebook: 'facebook',
    warning: 'warning',
    error: 'error',
    success: 'success',
    info: 'info',
    plus: 'plus',
    minus: 'minus',
    call: 'call',
    remove: 'trash',
    restart: 'restart',
    ussd: 'old-mobile',
    circleCross: 'times-circle-bts',
    circleCheck: 'check-circle-bts',
  },
};

export const pallets = [
  {
    name: 'White',
    key: 'white',
    color: theme.colors.baseWhite,
  },
  {
    name: 'Black',
    key: 'black',
    color: theme.colors.baseBlack,
  },
  {
    name: 'Yellow',
    key: 'yellow',
    color: theme.colors.brandYellow,
  },
  {
    name: 'Red',
    key: 'red',
    color: theme.colors.brandRed,
  },
  {
    name: 'RedYellow',
    key: 'redYellow',
    color: theme.colors.redBerry,
  },
  {
    name: 'Purple',
    key: 'purple',
    color: theme.colors.brandPurple,
  },
  {
    name: 'Navy',
    key: 'navy',
    color: theme.colors.brandNavy,
  },
  {
    name: 'Green',
    key: 'green',
    color: theme.colors.brandGreen,
  },
  {
    name: 'Violet',
    key: 'violet',
    color: theme.colors.brandViolet,
  },
  {
    name: 'Gray',
    key: 'gray',
    color: theme.colors.baseGrayVeryLight,
  },
  {
    name: 'WhiteYellow',
    key: 'whiteYellow',
    color: theme.colors.brandYellowPale,
  },
  {
    name: 'PurpleGreen',
    key: 'purpleGreen',
    color: theme.colors.brandGreenPop,
  },
  {
    name: 'PurpleOrange',
    key: 'purpleOrange',
    color: theme.colors.brandRedPop,
  },
  {
    name: 'XMas',
    key: 'xMas',
    color: theme.colors.redBright,
  },
  {
    name: 'WhiteRed',
    key: 'whiteRed',
    color: theme.colors.brandRedPale,
  },
  {
    name: 'GreenYellow',
    key: 'greenYellow',
    color: theme.colors.brandGreen,
  },
];

export const backgrounds = [...pallets];

import React from 'react';

import { Footer, ShowAt } from '@sygnia/components';
import { CONTENT_TYPES } from '../common/constants';
import {
  setupLink,
  setupContent,
  mapperWithFunction,
  setupPage,
} from '../common/utils';

export const MENU_CONTENT_MAP = {
  [CONTENT_TYPES.CONTENT_LINK]: {
    link: value => setupLink(value, {}),
  },
  [CONTENT_TYPES.CONTENT_PAGE]: {
    link: value => setupPage(value, {}),
  },
  [CONTENT_TYPES.CONTENT_PRODUCT]: {
    link: value => setupContent(value.label, value.slug, {}, 'product'),
  },
  [CONTENT_TYPES.CONTENT_FUND]: {
    link: value => setupContent(value.label, value.slug, {}, 'fund'),
  },
  [CONTENT_TYPES.CONTENT_PEOPLE]: {},
};

export const FooterMenuWrapper = ({ menu: { links = [] } }) => {
  const footerMenu = links
    .filter(m => m.__typename === CONTENT_TYPES.CONTENT_MENU)
    .map(m => {
      return {
        sectionHeader: m.title,
        links: m.menuItems
          .filter(link => MENU_CONTENT_MAP[link.__typename]) // safe guard against links that do not have a link model.
          .map(link => ({
            ...mapperWithFunction(link, MENU_CONTENT_MAP[link.__typename]).link,
          })),
      };
    });

  return (
    <>
      <ShowAt breakpoint="mediumAndBelow">
        <Footer footerSections={footerMenu} isMobile={true} />
      </ShowAt>
      <ShowAt breakpoint="large">
        <Footer footerSections={footerMenu} />
      </ShowAt>
    </>
  );
};

export default {
  black: {
    color: 'baseBlack',
    bg: 'baseWhite',
  },
  white: {
    color: 'baseWhite',
    bg: 'baseBlack',
  },
  green: {
    color: 'brandGreenBright',
    bg: 'brandGreenPale',
  },
  purple: {
    color: 'brandPurpleBright',
    bg: 'brandPurplePale',
  },
  violet: {
    color: 'brandVioletBright',
    bg: 'brandVioletPale',
  },
  red: {
    color: 'brandRedBright',
    bg: 'brandRedPale',
  },
  yellow: {
    color: 'brandYellowBright',
    bg: 'brandYellowPale',
  },
  navy: {
    color: 'brandNavyBright',
    bg: 'brandNavyPale',
  },
};

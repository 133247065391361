import React, { createContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

export const ResourceSetContext = createContext();

const GLOBAL_RESOURCES_QUERY = graphql`
  query getAllContentfulResourceSets {
    allContentfulResourceSet {
      nodes {
        name
        key
        value {
          internal {
            content
          }
        }
      }
    }
  }
`;

const getStructuredResourceSets = nodes => {
  return nodes.map(n => {
    return {
      component: n.key,
      resources: JSON.parse(n.value.internal.content),
    };
  });
};

export const getResourcesForComponent = (resourceSets, componentName) => {
  return (
    getStructuredResourceSets(resourceSets).find(
      obj => obj.component === componentName,
    )?.resources ?? {}
  );
};

export const ResourceSetProvider = ({ children }) => {
  const {
    allContentfulResourceSet: { nodes },
  } = useStaticQuery(GLOBAL_RESOURCES_QUERY);

  if (!nodes) {
    console.log('No Resource Sets found');
    return children;
  }

  return (
    <ResourceSetContext.Provider
      value={{
        resourceSets: getStructuredResourceSets(nodes),
        getResourcesForComponent: key => getResourcesForComponent(nodes, key),
      }}
    >
      {children}
    </ResourceSetContext.Provider>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mediaQuery } from '../common/utils';

const ShowAtLarge = styled.div`
  display: none;
  ${mediaQuery.small} {
    display: none;
  }
  ${mediaQuery.medium} {
    display: none;
  }
  ${mediaQuery.large} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
`;

const ShowAtMedium = styled.div`
  display: none;
  ${mediaQuery.small} {
    display: none;
  }
  ${mediaQuery.medium} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.large} {
    display: none;
  }
`;

const ShowAtMediumAndBelow = styled.div`
  display: ${props => (props.flex ? 'flex' : 'block')};
  ${mediaQuery.small} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.medium} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.large} {
    display: none;
  }
`;

const ShowAtMediumAndAbove = styled.div`
  display: none;
  ${mediaQuery.small} {
    display: none;
  }
  ${mediaQuery.medium} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
  ${mediaQuery.large} {
    display: ${props => (props.flex ? 'flex' : 'block')};
  }
`;

const ShowAtSmall = styled.div`
  display: ${props => (props.flex ? 'flex' : 'block')};
  ${mediaQuery.medium} {
    display: none;
  }
  ${mediaQuery.large} {
    display: none;
  }
`;

export default function ShowAt({ breakpoint, flex, children }) {
  let Component;

  switch (breakpoint) {
    case 'small':
      Component = ShowAtSmall;
      break;
    case 'medium':
      Component = ShowAtMedium;
      break;
    case 'mediumAndBelow':
      Component = ShowAtMediumAndBelow;
      break;
    case 'mediumAndAbove':
      Component = ShowAtMediumAndAbove;
      break;
    case 'large':
      Component = ShowAtLarge;
      break;
    default:
      Component = React.Fragment;
  }

  return <Component flex={flex}>{children}</Component>;
}

ShowAt.propTypes = {
  /** Breakpoint for various viewports */
  breakpoint: PropTypes.string,
  /** Should the display be flex? else set to block */
  flex: PropTypes.string,
  /** Children node wrapper */
  children: PropTypes.node,
};

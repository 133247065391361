import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { strip_html_tags, mapper } from '../common/utils';
import { TEMPLATE_TYPES } from '../common/constants';
import { ResourceSetContext } from '../context/ResourceSetContext';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';

export const HelmetWrapper = props => {
  const { pathname } = useLocation();

  const {
    section: { summary = null, structuredData = null, url, template },
  } = props;

  const context = useContext(ResourceSetContext);
  const globalRS = context && context.getResourcesForComponent('global');
  const schemaJsonLd = globalRS && template && getJsonLd(globalRS, template);

  const SUMMARY_MAP = {
    title: ['title'],
    description: ['content', 'childMarkdownRemark', 'excerpt'],
    image: ['image', 'file', 'url'],
  };

  const SD_MAP = {
    title: ['title'],
    description: ['description', 'description'],
    content: ['content'],
    altText: ['altText'],
    image: ['image', 'fluid', 'src'],
    noindex: ['noindex'],
    nofollow: ['nofollow'],
  };

  const summaryObj = mapper(summary, SUMMARY_MAP);
  const sdObj = mapper(structuredData, SD_MAP);

  const title = sdObj.title
    ? sdObj.title
    : summaryObj.title
    ? summaryObj.title
    : globalRS.websiteName;

  const description = sdObj.description
    ? sdObj.description
    : sdObj.content
    ? sdObj.content
    : summaryObj.description
    ? strip_html_tags(summaryObj.description)
    : globalRS.description;

  const image = sdObj.image
    ? `https:${sdObj.image}`
    : summaryObj.image
    ? `https:${summaryObj.image}`
    : globalRS.image;

  const altText = sdObj.altText || globalRS.altText;
  const pageUrl = `${globalRS.websiteURL}${pathname}`;

  return (
    <Helmet defaultTitle={title} htmlAttributes={{ lang: globalRS.language }}>
      <link rel="canonical" href={pageUrl} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />
      {sdObj.noindex && <meta name="robots" content="noindex" />}
      {sdObj.nofollow && <meta name="robots" content="nofollow" />}
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content={globalRS.projectType} />
      <meta property="og:site_name" content={globalRS.websiteName} />
      <meta property="og:image" itemprop="image" content={image} />
      <meta property="og:image:alt" content={altText} />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={description}
        name="og:description"
      />
      <meta property="description" content={description} name="description" />
      <meta property="twitter:title" name="twitter:title" content={title} />
      <meta
        property="twitter:description"
        name="twitter:description"
        content={description}
      />
      <meta property="twitter:image" name="twitter:image" content={image} />
      <meta
        property="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        property="twitter:site"
        name="twitter:site"
        content={globalRS.websiteName}
      />

      {schemaJsonLd && (
        <script type="application/ld+json">
          {JSON.stringify(schemaJsonLd)}
        </script>
      )}
    </Helmet>
  );
};

export const getJsonLd = (seoProps, template) => {
  switch (template) {
    case TEMPLATE_TYPES.HOME: {
      return {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        '@id': '',
        isPartOf: {
          '@type': 'WebSite',
          url: seoProps?.websiteName || null,
          '@id': '/#this-site',
          publisher: { '@id': '/#this-org' },
        },
        publisher: {
          '@type': 'Organization',
          '@id': '/#this-org',
          name: seoProps?.websiteName || null,
          legalName: seoProps?.websiteName || null,
          url: seoProps?.websiteName || null,
          image: seoProps?.image || null,
          logo: seoProps?.logo || null,
          telephone: seoProps?.telephone || null,
          email: seoProps?.email || null,
          foundingDate: seoProps?.foundingDate || null,
          founders: [
            {
              '@type': 'Organization',
              name: seoProps?.websiteName || null,
            },
          ],
          address: [
            {
              '@type': 'PostalAddress',
              streetAddress: seoProps?.streetAddress || null,
              addressLocality: seoProps?.addressLocality || null,
              addressRegion: seoProps?.addressRegion || null,
              postalCode: seoProps?.postalCode || null,
              addressCountry: seoProps?.addressCountry || null,
            },
          ],
          contactPoint: {
            '@type': 'ContactPoint',
            contactType: 'customer support',
            telephone: seoProps?.telephone || null,
            email: seoProps?.email || null,
          },
          sameAs: seoProps?.sameAs || null,
        },
      };
    }

    // no default
  }
};

export const fragment = graphql`
  fragment StructuredDataFields on ContentfulStructuredData {
    __typename
    contentful_id
    title
    description {
      description
    }
    image {
      fluid {
        src
      }
    }
    altText
    noindex
    nofollow
  }
`;

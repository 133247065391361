import { useSpring } from 'react-spring';
import { theme } from './theme';

export const useVerticalSpring = (shouldAnimate, entering, startingOffset) => {
  return shouldAnimate
    ? useSpring({
        to: {
          opacity: entering ? theme.opacity.full : theme.opacity.none,
          y: entering ? 0 : startingOffset,
        },
        from: {
          opacity: theme.opacity.none,
          y: startingOffset,
        },
      })
    : useSpring({ to: { opacity: theme.opacity.full, y: 0 } });
};

export const useHorizontalSpring = (
  shouldAnimate,
  entering,
  startingOffset,
) => {
  return shouldAnimate
    ? useSpring({
        to: {
          opacity: entering ? theme.opacity.full : theme.opacity.none,
          x: entering ? 0 : startingOffset,
        },
        from: {
          opacity: theme.opacity.none,
          x: startingOffset,
        },
      })
    : useSpring({ to: { opacity: theme.opacity.full, x: 0 } });
};

export const animateOpacity = inView => {
  return useSpring({
    from: { opacity: inView ? 0 : 1 },
    to: { opacity: inView ? 1 : 0 },
    config: { duration: 300 },
  });
};

import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import { Box, Flex } from 'rebass';
import { theme } from '../common/theme';

// Icon to be used with Action Buttons
const Icon = ({ name, isIconAfter }) => {
  const config = {
    pl: isIconAfter ? '8px' : '0',
    pr: !isIconAfter ? '8px' : '0',
  };
  return (
    name && (
      <Box fontSize="20px" {...config}>
        <i className={`icon icon-${name}`} />
      </Box>
    )
  );
};

Icon.defaultProps = {
  isIconAfter: false,
  name: null,
};

const ButtonAction = ({ children, isIconAfter, ...props }) => {
  const config = {
    variant: 'action',
    bg: 'transparent',
    borderColor: 'transparent',
  };

  return (
    <Button {...props} {...config}>
      <Flex alignItems="center">
        {!isIconAfter ? <Icon name={props.icon} /> : null}
        {children}
        {isIconAfter ? <Icon isIconAfter={true} name={props.icon} /> : null}
      </Flex>
    </Button>
  );
};

ButtonAction.defaultProps = {
  isIconAfter: false,
  default: null,
  icon: null,
  color: 'black',
  px: '12px',
  py: '10px',
};

ButtonAction.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOf(Object.values(theme.icons)),
  isIconAfter: PropTypes.bool,
};

export default ButtonAction;
